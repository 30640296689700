import React from "react";

import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/utils/seo";

import "./404.css";

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="Página no existe"></SEO>
      <section className="not-found-wrapper">
        <h2>Página no encontrada.</h2>
        <p>
          La página que buscas no existe o fué movida, por favor intenta ir a
          nuestra página de <Link to="/">inicio.</Link>
        </p>
      </section>
    </Layout>
  );
};

export default NotFoundPage;
